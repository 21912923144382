import React, { useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


function Introduction() {
    const [showModal, setShowModal] = useState(false);


    return (<>
        <div className='md:p-9 p-5 lg:pb-24 md:pb-28 bg-gradient-to-r from-pink-200 via-purple-200 to-indigo-300 '>
            {/* <div className='md:p-9 p-5 pb-40 inset-0 bg-gradient-to-t to-transparent via-slate-900/50 from-slate-900/90'> */}
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-5 md:px-5'>
                <div className='md:m-12'>
                    <div className='flex flex-col'>
                        <h1 className='w-fit inline-block bg-[#ffc85b] text-white rounded-lg px-4 py-1 font-bold lg:text-lg text-sm'>WELCOME TO BACCHAPANTI !</h1>
                        <div className='mt-2'>
                            <h1 className='text-[#12265a] lg:text-4xl text-2xl font-bold'>A Journey from <br/><span className='lg:text-5xl text-3xl  font-extrabold'> BETTER TO BEST !</span></h1>
                            <p className='mt-2 text-[#70747F]'>We work everyday to build the foundation of amazing futures, both for the child and for our community.</p>
                        </div>
                        <div className='mt-10'>
                            <Link to="/contact" className='relative w-auto inline-block text-center rounded-full bg-white hover:bg-[#ffc85b] text-[#45b3df] hover:text-white p-2 transition duration-500'>
                                <div className='border-2 border-dashed border-[#45b3df] rounded-full p-3 px-20 text-base font-bold hover:border-white'>
                                    Free Demo
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='relative text-center lg:mt-12 my-8'>
                    {
                        showModal ? (null) :
                            (
                                <div className='lg:top-[68%] top-[70%] md:ml-[-15px] ml-[-25px]  inline-block absolute'>
                                    <div onClick={() => setShowModal(true)} className="md:w-12 md:h-12 w-5 h-5 cursor-pointer bg-pink-200 rounded-full ring-4 ring-blue-200 grid place-items-center hover:bg-pink-400 hover:ring-blue-400 transition">
                                        <svg className="ml-1 md:w-4 w-2" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z" fill="white" />
                                        </svg>
                                    </div>
                                </div>
                            )
                    }
                    {
                        showModal ? (
                            <div onClick={() => setShowModal(false)} className="z-[1060]">
                                <div className="">
                                    <div className='absolute right-0 w-12 h-12 cursor-pointer bg-pink-200 rounded-full ring-4 ring-blue-200 grid place-items-center hover:bg-pink-400 hover:ring-blue-400 transition' onClick={() => setShowModal(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="w-8 h-6">
                                            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <iframe width="100%" height="400px" className='rounded-lg' src="https://www.youtube.com/embed/wwq39t_Qoy8?si=P_0Tq3iPvoQlkCPi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </div>
                        ) : <div>
                            <img src="assets/Img/Introduction.jpg" className='rounded-lg h-full w-full' />
                        </div>
                    }

                </div>

            </div>
        </div>
        <img src="assets/ColumnImg/home1_slider_1_wave.png" className='w-full xxl:-mt-28 lg:-mt-16' />
    </>
    )
}

export default Introduction