import React, { useState } from "react";
import emailjs from "emailjs-com";
import ModalForm from "../Contact/ModalForm";

const DemoForm = () => {
  const [email, setEmail] = useState("");
  const [studentAge, setStudentAge] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showModal, setShowModal] = useState(false); // State to control modal display
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [studentName, setStudentName] = useState("");
  const [childModalName, setChildModalName] = useState("");

  // Regular expression for email validation
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const handleSubmit = (e) => {
    e.preventDefault();
    setChildModalName(`${studentName}`);
    // Validate phone number
    if (!validatePhoneNumber(phoneNumber)) {
      setPhoneError("Please enter a valid phone number (with country code)");
      return;
    } else {
      setPhoneError("");
    }

    // Validate email
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    } else {
      setEmailError("");
    }

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: studentName,
      from_email: email,
      from_phoneNumber: phoneNumber,
      to_name: "Bacchapanti",
      kidsAge: studentAge,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setShowModal(true); // Show modal on successful submission
        setStudentName("");
        setEmail("");
        setPhoneNumber("");
        setStudentAge("");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const closeModal = () => {
    setShowModal(false); // Function to close the modal
  };

  // Function to validate phone number (exactly 10 digits)
  const validatePhoneNumber = (phone) => {
    // Regular expression to match phone number with optional country code
    const phoneRegex =
      /^\+?[0-9]{1,3}?[-. ]?\(?[0-9]{3}\)?[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/;
    return phoneRegex.test(phone);
  };

  // Function to validate email address
  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  return (
    <div className="">
      <div className="">
        <form className="items-center flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="flex gap-6">
                <div className="section">
          <input
            className={`bg-transparent rounded-2xl border-2 border-white-200 placeholder-grey w-full py-4 px-8 text-black leading-tight focus:outline-none focus:shadow-outline ${
              phoneError && "border-red-500"
            }`}
            placeholder="WhatsApp Phone Number"
            name="phoneNumber"
            type="tel"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              setPhoneError("");
            }}
            required
          />
          {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}
          </div>

          <div className="section">
          <input
            className={`bg-transparent rounded-2xl border-2 border-white-200 placeholder-grey w-full py-4 px-8 text-black leading-tight focus:outline-none focus:shadow-outline ${
              emailError && "border-red-500"
            }`}
            placeholder="Parent Email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
          </div>
          </div>
          <div className="flex gap-6">
          <input
            className={`bg-transparent rounded-2xl border-2 border-white-200 placeholder-grey w-full py-4 px-8 text-black leading-tight focus:outline-none focus:shadow-outline ${
              phoneError && "border-red-500"
            }`}
            placeholder="Students Name"
            name="Name"
            type="text"
            value={studentName}
            onChange={(e) => setStudentName(e.target.value)}
            required
          />

            <input
            className={`bg-transparent rounded-2xl border-2 border-white-200 placeholder-grey w-full py-4 px-8 text-black leading-tight focus:outline-none focus:shadow-outline` }
            placeholder="Student Age"
            name="Name"
            type="text"
            value={studentAge}
            onChange={(e) => setStudentAge(e.target.value)}
            required
          />
         </div>
          <button
            type="submit"
            className=" w-32 text-base mb-5 rounded-full inline-block text-white bg-[#f17105]  hover:bg-[#ffbc0a]  p-2 transition duration-500"
          >
            Book Demo
          </button>
          {showModal && (
            <ModalForm
              closeModal={closeModal}
              parentsModalName={childModalName}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default DemoForm;
