import React from 'react'
import PageTitle from '../Header/PageTitle'
import ContactList from './ContactList'
import ContactForm from './ContactForm'

function Contact() {
  return (
    <div>
      <PageTitle title="Contact Us" activeTitle="Contact Us" />
      <div className='mx-auto max-w-[1920px] px-4 md:px-8 2xl:px-16 pt-28'>
       <ContactList />
       <ContactForm />
      </div>
    </div>
  )
}

export default Contact