import React from 'react'

function InputField({placeholder, label, inputField, handleInputField, productname, inputType}) {


    
    return (
        <>
            <label className='block  text-[#566a7f] font-medium text-sm  mb-2'>{label}</label>
            <input className="block w-full border border-[#d9dee3] rounded-md py-3 px-4 appearance-none leading-tight focus:outline-none focus:shadow-outline"
                type={inputType}
                placeholder={placeholder}
                value={inputField}
                onChange={handleInputField}
                name={productname}
                aria-label="Product title"
                autoComplete="off"></input>
        </>
    )
}

export default InputField