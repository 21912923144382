import React from 'react'
import Heading from '../Heading/Heading'
import KidsModal from '../ModalVideo/KidsModal'
import Button from '../Heading/Button'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const KidReviews = [
    {
        kidsImg: 'assets/Reviews/KidsReview.png',
        kidsVideo: 'https://www.youtube.com/embed/XUh_Dj8_HzY'
    },
    {
        kidsImg: 'assets/Reviews/KidsReview2.png',
        kidsVideo: 'https://www.youtube.com/embed/6U-Fo1riLC0?si=AUFbPeYDwGr7uWzI'
    },
    {
        kidsImg: 'assets/Reviews/KidsReview3.png',
        kidsVideo: 'https://www.youtube.com/embed/rV7qfrLL61M?si=6S6dkhZyfId_4iZ1'
    },
    {
        kidsImg: 'assets/Reviews/KidsReview4.png',
        kidsVideo: 'https://www.youtube.com/embed/EdZLeX2ys6A'
    },
]


function KidsReview() {
    return (
        <div className='md:mt-28 mt-12'>
            <div className='p-20' style={{ backgroundImage: `url("assets/ColumnImg/cloudTransparent.png")` }}></div>
            <div className='bg-[#fcf9f4] pb-20 px-5'>
                <div className='grid grid-cols-12 mb-10 justify-between'>
                    <div className='md:col-span-10 col-span-8'>
                        <Heading HeadingOne="Kid's" HeadingTwo="Reviews" />
                    </div>
                    <div className='md:col-span-2 col-span-4 content-center text-center'>
                        <Link to="/gallery"><Button buttonText="View More" /></Link>
                    </div>
                </div>
                <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2'>
                    {
                        KidReviews.map((kids) => {
                            return (
                                <>
                                    <div className='relative text-center'>
                                        <img src={kids.kidsImg} className='rounded-lg w-full lg:h-60 md:h-60' />
                                        <KidsModal VideoModalVideos={kids.kidsVideo} />
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </div>
            <div className='p-10' style={{ backgroundImage: `url("assets/ColumnImg/cloudTransparentBottom.png")` }}></div>
        </div>
    )
}

export default KidsReview