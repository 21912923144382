import React from 'react'



function Introduction() {
    return (<>
        <div className='md:p-9 p-5  bg-gradient-to-b from-[#f9dced]  to-[#e0dbf9] '>

            <div className='grid lg:grid-cols-2 grid-cols-1 container gap-5 md:px-5'>
                    <div className='flex flex-col'>
                        <div className='mt-2'>
                            <h1 className='lg:text-5xl pt-8 text-5xl leading-[70px] font-extrabold'>Transforming Math Fear Into "Fun & Confidence"</h1>
                            <p className='mt-12 font-semibold text-[20px] text-black'>Empowering Every Student With The Joy of Math</p>
                        </div>
                    </div>
                <div className='relative pl-24 text-center'>
                    
                <img src="assets/Img/banner-image2.png" className='rounded-lg '/>
                       
                    
                </div>

            </div>
           
        </div>
        <div className='container '>
            <div className='bg-[#554be6] rounded-3xl pt-12 pb-12 -mt-10 ml-16 text-center text-white text-4xl font-bold leading-snug'>
            Live and Interactive Online Math<br />
            Classes For UKG-10
            </div>
        </div>
        
    </>
    )
}

export default Introduction