import React, { useState } from "react";
import emailjs from "emailjs-com";
import ModalForm from "../Contact/ModalForm";

const BookForm = () => {
  const [childName, setChildName] = useState("");
  const [email, setEmail] = useState("");
  const [kidsAge, setKidsAge] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showModal, setShowModal] = useState(false); // State to control modal display
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [childModalName, setChildModalName] = useState("");

  // Regular expression for email validation
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const handleSubmit = (e) => {
    e.preventDefault();
    setChildModalName(`${childName}`);
    // Validate phone number
    if (!validatePhoneNumber(phoneNumber)) {
      setPhoneError("Please enter a valid phone number (with country code)");
      return;
    } else {
      setPhoneError("");
    }

    // Validate email
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    } else {
      setEmailError("");
    }

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: childName,
      from_email: email,
      from_phoneNumber: phoneNumber,
      to_name: "Bacchapanti",
      kidsAge: kidsAge,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setShowModal(true); // Show modal on successful submission
        setChildName("");
        setEmail("");
        setPhoneNumber("");
        setKidsAge("");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const closeModal = () => {
    setShowModal(false); // Function to close the modal
  };

  // Function to validate phone number (exactly 10 digits)
  const validatePhoneNumber = (phone) => {
    // Regular expression to match phone number with optional country code
    const phoneRegex =
      /^\+?[0-9]{1,3}?[-. ]?\(?[0-9]{3}\)?[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/;
    return phoneRegex.test(phone);
  };

  // Function to validate email address
  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  return (
    <div className="bg-[#8abd97] rounded-3xl p-8 md:pl-10">
      <h5 className="text-white text-4xl font-bold">
        Book a Free Class for your child!
      </h5>
      <div className="w-full pt-8 max-w-xl">
        <form className="emailForm flex flex-col gap-4" onSubmit={handleSubmit}>
          <input
            className={`bg-transparent rounded-2xl border-2 border-white-200 placeholder-white w-full py-4 px-8 text-white leading-tight focus:outline-none focus:shadow-outline ${
              phoneError && "border-red-500"
            }`}
            placeholder="Parent's Mobile Number"
            name="phoneNumber"
            type="tel"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              setPhoneError("");
            }}
            required
          />
          {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}
          <input
            className={`bg-transparent rounded-2xl border-2 border-white-200 placeholder-white w-full py-4 px-8 text-white leading-tight focus:outline-none focus:shadow-outline ${
              emailError && "border-red-500"
            }`}
            placeholder="Parent Email ID"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
          <input
            className={`bg-transparent rounded-2xl border-2 border-white-200 placeholder-white w-full py-4 px-8 text-white leading-tight focus:outline-none focus:shadow-outline ${
              phoneError && "border-red-500"
            }`}
            placeholder="Child Name"
            name="Name"
            type="text"
            value={childName}
            onChange={(e) => setChildName(e.target.value)}
            required
          />
          <select
            className="bg-transparent rounded-2xl border-2 border-white-200 placeholder-white w-full py-4 px-8 text-white leading-tight focus:outline-none focus:shadow-outline"
            name="grade"
            value={kidsAge}
            onChange={(e) => setKidsAge(e.target.value)}
            required
          >
            <option value="" disabled>
              Select Child's Grade
            </option>
            <option value="Grade 1" className="text-black">
              Grade 1
            </option>
            <option value="Grade 2" className="text-black">
              Grade 2
            </option>
            <option value="Grade 3" className="text-black">
              Grade 3
            </option>
            <option value="Grade 4" className="text-black">
              Grade 4
            </option>
            <option value="Grade 5" className="text-black">
              Grade 5
            </option>
            <option value="Grade 6" className="text-black">
              Grade 6
            </option>
            <option value="Grade 7" className="text-black">
              Grade 7
            </option>
            <option value="Grade 8" className="text-black">
              Grade 8
            </option>
          </select>

          {/* <button
            type="submit"
            className=" inline-block w-full text-center rounded-full bg-[#ffc85b]  hover:bg-[#45b3df] text-white p-2 transition duration-500"
          >
            <div className="border-2 border-dashed border-white rounded-full p-3 text-base font-bold">
              Submit
            </div>
          </button> */}
          <button
            type="submit"
            className=" w-56 p-2 text-base rounded-full inline-block bg-[#f17105]  hover:bg-[#45b3df] text-white p-2 transition duration-500"
          >
            Book Free Demo
          </button>
          {showModal && (
            <ModalForm
              closeModal={closeModal}
              parentsModalName={childModalName}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default BookForm;
