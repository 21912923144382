import React, { useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


function Introduction() {
    const [showModal, setShowModal] = useState(false);


    return (
    <>
            {/* Arch Effect */}
            <div className="mt-4">

            </div>

        <div className='bg-[#f17105] relative z-0 pt-11 pb-11' style={{
     clipPath: `ellipse(154% 100% at 50% 100%)`
            }}>
            <div className='grid container  items-center lg:grid-cols-2  grid-cols-1 gap-5'>
                <div className='md:m-12'>
                    <div className='flex flex-col pr-9'>
                        <h1 className='w-fit inline-block text-white rounded-lg px-4 py-1 font-seaweedScript text-2xl'>Unlock your child's full potential</h1>
                            <h1 className='text-white text-2xl md:text-3xl lg:text-5xl font-bold leading-[60px] mb-6'>Help them Master <br></br> Focus and Excel Academically!<br/></h1>
                            <p className=' text-white text-lg'>Is your child struggling to concentrate on their studies and getting distracted by social media? <br/> With Bachhapanti, they can unlock their full potential!</p>
                        <div className='mt-10'>
                            <Link to="/contact" className='relative w-auto inline-block text-center rounded-xl bg-white hover:bg-[#ffc85b] text-[#f17105] hover:text-white p-2 transition duration-500'>
                                <div className=' p-2 px-5 text-base font-bold hover:border-white'>
                                    Contact Us
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className=' text-center lg:mt-12 my-8'>
                     <img src="assets/Img/IntroductionSecond.jpg" className='border-8 border-white rounded-full  w-full' />
                </div>
            </div>
        </div>
   
       
    </>
    )
}

export default Introduction