import React, { useEffect, useState } from 'react'
import { useFirebase } from '../Helper/Firebase';
import { toast } from 'react-toastify';

const Gallery = ["All Photos", "Meditation", "Brain Gym Exercise", "BlindFold", "Rewards", "News & Interview", "Events & Workshop"]

toast.configure();

function GalleryPhotos() {
    const firebase = useFirebase();
    const [toggleState, setToggleState] = useState(Gallery[0]);
    const [GalleryData, setGalleryData] = useState([]);
    const [CategoryName, setCategoryName] = useState(Gallery[0]);

    const toggleTab = (index) => {
        setToggleState(index);
        setCategoryName(index);
    };

    useEffect(() => {
        loadData();
    }, [toggleState])

    const loadData = () => {
        firebase.fileData("Image", CategoryName).then((data) => setGalleryData(data.docs));
    }

    return (
        <div className='mt-8'>
            <div className='flex flex-wrap justify-center flex-grow gap-7'>
                {Gallery.map((items, index) => (<>
                    <div key={index}>
                        <button
                            className={toggleState === items ? "relative block bg-none text-[#45b3df] text-2xl font-extrabold after:absolute after:left-0 after:bottom-[-12px] after:bg-[#45b3df] after:h-1 after:w-full" : "relative block font-bold bg-none text-[#fa9db7] text-2xl"}
                            onClick={() => toggleTab(items)}
                        >
                            {items}
                        </button>
                    </div>
                </>))}
                <div>
                    <div className='grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-5 pt-12'>
                        {
                            GalleryData.map((imagesItem, imagesIndex) => {
                                return (<>
                                    <div key={imagesItem.data().id} className='overflow-hidden flex rounded-3xl cursor-pointer ltr:pr-0 rtl:pl-0 flex-col items-start transition duration-200 ease-in-out transform hover:-translate-y-1 md:hover:-translate-y-1.5 hover:shadow-sm bg-white'>
                                        <img src={imagesItem.data().fileUrl} style={{ width: '100%', height: 'auto' }} className="block max-w-full bg-none opacity-100 border-0 m-0 p-0" />
                                    </div>
                                    {/* <div className='w-full md:w-1/6 px-3 md:mb-0'>
                                        {
                                            <button onClick={() => deleteButton(imagesItem.data().fileUrl, imagesItem.data().id)} className="bg-blue-500 text-white px-3 py-2 rounded-md">Delete</button>
                                        }
                                    </div> */}
                                </>)
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GalleryPhotos