import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import EventModal from "../ModalVideo/EventModal";

const Events = () => {
  const IndianEvents = [
    {
      eventImg: "assets/Reviews/KidsReview.png",
      eventVideo: "https://www.youtube.com/embed/XUh_Dj8_HzY",
    },
    {
      eventImg: "assets/Reviews/KidsReview2.png",
      eventVideo: "https://www.youtube.com/embed/6U-Fo1riLC0?si=AUFbPeYDwGr7uWzI",
    },
    {
        eventImg: "assets/Reviews/KidsReview3.png",
      eventVideo: "https://www.youtube.com/embed/rV7qfrLL61M?si=6S6dkhZyfId_4iZ1",
    },
  ];

  const UsaEvents = [
   
    {
      eventImg: "assets/Reviews/KidsReview2.png",
      eventVideo: "https://www.youtube.com/embed/6U-Fo1riLC0?si=AUFbPeYDwGr7uWzI",
    },
    {
        eventImg: "assets/Reviews/KidsReview3.png",
      eventVideo: "https://www.youtube.com/embed/rV7qfrLL61M?si=6S6dkhZyfId_4iZ1",
    },
    {
        eventImg: "assets/Reviews/KidsReview.png",
        eventVideo: "https://www.youtube.com/embed/XUh_Dj8_HzY",
      },
  ];


  return (
    <div className="mt-8">
      <div
        className="relative"
        style={{
            backgroundImage: `url("/assets/Img/bgImage.jpg")`,
            backgroundPosition: "center",
            WebkitMask: `
              radial-gradient(63.09px at 50% 85.5px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 51% repeat-x,
              radial-gradient(63.09px at 50% -55.5px, #0000 99%, #000 101%) 50% 30px/120px calc(51% - 30px) repeat-x,
              radial-gradient(63.09px at 50% calc(100% - 85.5px), #000 99%, #0000 101%) calc(50% - 60px) 100%/120px 51% repeat-x,
              radial-gradient(63.09px at 50% calc(100% + 55.5px), #0000 99%, #000 101%) 50% calc(100% - 30px)/120px calc(51% - 30px) repeat-x
            `,
            mask: `
              radial-gradient(63.09px at 50% 85.5px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 51% repeat-x,
              radial-gradient(63.09px at 50% -55.5px, #0000 99%, #000 101%) 50% 30px/120px calc(51% - 30px) repeat-x,
              radial-gradient(63.09px at 50% calc(100% - 85.5px), #000 99%, #0000 101%) calc(50% - 60px) 100%/120px 51% repeat-x,
              radial-gradient(63.09px at 50% calc(100% + 55.5px), #0000 99%, #000 101%) 50% calc(100% - 30px)/120px calc(51% - 30px) repeat-x
            `,
          }}
      >
        <div className="absolute inset-0 bg-[#ea720c] opacity-90 z-0 pointer-events-none"></div>
        <div className="container pt-16 pb-16 mx-auto grid grid-cols-12 gap-6" >
       
          <div className=" z-10  p-10 pb-5 rounded-xl rounded-l-[40px] col-span-full text-center">
            <h5>
              <span className="text-[35px] font-seaweedScript font-medium text-[#ffbc0a]">
                Events & Workshops
              </span>
            </h5>
            <h3 className="text-2xl text-white font-extrabold md:text-4xl lg:text-5xl">
              Making education fun for children
            </h3>
          </div>

          <Tabs className="col-span-12 z-10">
            <TabList className="flex justify-center mb-6 gap-0 text-white">
              <Tab
                className="px-6 py-3  text-xl border-b-2 font-bold cursor-pointer"
                selectedClassName="border-[#ffbc0a] text-[#ffbc0a] outline-0"
              >
              India
              </Tab>
              <Tab
                className="px-6 py-3 border-b-2 text-lg font-bold  cursor-pointer"
                selectedClassName="border-[#ffbc0a]  text-[#ffbc0a] outline-0 "
              >
               USA
              </Tab>
            </TabList>
             {/* Indian Panel */}
            <TabPanel className="col-span-12 mt-14">
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 mb-7">
                {IndianEvents.map((india, index) => (
                  <div
                    key={index}
                    className="relative text-center bg-white shadow-lg rounded-tl-[50px] rounded-br-[50px] overflow-hidden border-4 border-[#fff]"
                  >
                    <img
                      src={india.eventImg}
                      className="w-full lg:h-60 md:h-60 object-cover"
                      alt={`Kid review ${index + 1}`}
                    />
                    <EventModal VideoModalVideos={india.eventVideo} />
                  </div>
                ))}
              </div>
            </TabPanel>
               {/* Use Panel */}
            <TabPanel className="col-span-12 mt-14">
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 mb-7">
                {UsaEvents.map((usa, index) => (
                  <div
                    key={index}
                    className="relative text-center bg-white shadow-lg rounded-tl-[50px] rounded-br-[50px] overflow-hidden border-4 border-[#fff]"
                  >
                    <img
                      src={usa.eventImg}
                      className="w-full lg:h-60 md:h-60 object-cover"
                      alt={`Kid review ${index + 1}`}
                    />
                    <EventModal VideoModalVideos={usa.eventVideo} />
                  </div>
                ))}
              </div>
            </TabPanel>
          </Tabs>
        </div>
        </div>
    </div>
  );
};
export default Events;
