import React from 'react'
import Heading from '../Heading/Heading'
import Button from '../Heading/Button'
import SideIcon from '../SideIcon/SideIcon'

function BestClasses() {
    return (
        <div className='md:mt-28 mt-12'>
            <SideIcon
                floatingLeftIcon="school.png"
                floatingRightIcon="laptop.png"
            />
            <div className='text-center mb-10'>
                <Heading HeadingOne="On Going" HeadingTwo="Best Classes" />
            </div>
            <div className='lg:px-20 px-5'>
                <div className='grid md:grid-cols-7 gap-4 text-center'>
                    <div className='col-span-3'>
                        <div className=''>
                            <span className="box-border p-5 rounded-3xl shadow-md bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 inline-block w-full opacity-100 border-0 m-0 relative max-w-full">
                                <span style={{ width: "initial", height: "initial" }} className="box-border h-96 inline-block bg-none opacity-100 border-0 m-0 p-0 relative max-w-full">
                                    <img src="assets/Img/Offline.png" className="min-w-full rounded-3xl max-w-full min-h-full max-h-full w-full block object-cover  bg-none opacity-100 border-0 m-0 p-0" />
                                </span>
                            </span>
                            <h2 className="md:text-4xl text-xl font-extrabold dark:text-white">OFFLINE</h2>
                        </div>
                    </div>
                    <div className='col-span-1 flex self-center justify-center'>
                        <img src="assets/svg/fly.png" className='text-center md:block hidden' />
                    </div>
                    <div className='col-span-3'>
                        <div className=''>
                            <span className="box-border p-5 rounded-3xl shadow-md bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 inline-block w-full opacity-100 border-0 m-0 relative max-w-full">
                                <span style={{ width: "initial", height: "initial" }} className="box-border inline-block bg-none opacity-100 border-0 m-0 p-0 relative max-w-full">
                                    <img src="assets/Img/Onlineclass.jpeg" className="min-w-full rounded-3xl max-w-full min-h-full max-h-full w-full block object-cover  bg-none opacity-100 border-0 m-0 p-0" />
                                </span>
                            </span>
                            <h2 className="md:text-4xl text-xl font-extrabold dark:text-white">ONLINE</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BestClasses