import React from 'react'
import SideIcon from '../SideIcon/SideIcon'
import Heading from '../Heading/Heading'
import Button from '../Heading/Button'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'


const teamMember = [
    {
        memberImage: 'assets/TeamImg/Lalit K Garg.jpg',
        memberName: 'Lalit K Garg',
        memberDesignation: 'Founder & CEO ',
        memberDescription: 'Lalit K Garg, the visionary Founder & CEO of Bacchapanti, brings unparalleled dedication and innovation to the realm of education. With a deep-rooted commitment to empowering learners and transforming education, Lalit’s leadership steers Bacchapanti towards excellence and impact. Join us in our journey to redefine education under the guidance of Lalit K Garg, driving positive change and creating opportunities for growth and success.'
    },
    {
        memberImage: 'assets/TeamImg/Ruchi Gupta.jpg',
        memberName: 'Ruchi Gupta',
        memberDesignation: 'Global Head Teaching',
        memberDescription: 'Ruchi Gupta, our esteemed Global Head of Teaching at Bacchapanti, embodies a passion for educational excellence and a commitment to nurturing young minds worldwide. With a deep understanding of pedagogy and innovative teaching methodologies, Ruchi leads our team of educators to inspire curiosity and foster a love for learning. Join us in our mission to empower the next generation with Ruchi Gupta’s visionary leadership, creating transformative educational experiences on a global scale.'
    },
    {
        memberImage: 'assets/TeamImg/Rajendar Prasad.jpg',
        memberName: 'Rajendar Kala',
        memberDesignation: 'Head Course Development',
        memberDescription: 'Rajendra Kala, our dynamic Head of Course Development at Bacchapanti, leads the creation of innovative and engaging learning experiences. With a focus on curriculum design and instructional strategies, Rajendra ensures that our courses meet the highest educational standards. Join us in our mission to inspire and educate learners with Rajendra Kala’s visionary leadership, where every course is designed to empower minds and foster success.'
    },
    {
        memberImage: 'assets/TeamImg/Priyanka Goyal.jpg',
        memberName: 'Priyanka Goyal',
        memberDesignation: 'Right Brain Executor-Balpan',
        memberDescription: 'Priyanka Goyal, our dedicated Right Brain Executor-Balpan at Bacchapanti, brings a unique blend of creativity and strategic thinking to drive innovative initiatives. With a focus on unleashing creative potential and fostering out-of-the-box solutions, Priyanka leads our efforts in developing engaging educational content and experiences. Join us in our mission to inspire creativity and ignite imaginations under Priyanka Goyal’s guidance, where every idea finds expression and every learner discovers their true potential.'
    }


]

let colors = ['rgb(250,157,183)', 'rgb(253,197,99)', 'rgb(69,179,223)', '#95adfa', '#b6dd6b', '#d8a7e2']

function OurTeam() {
    return (<>
        <div className='md:mt-28 mt-12 px-5'>
            <SideIcon
                floatingLeftIcon="femaleTeacher.png"
                floatingRightIcon="maleTeacher.png"
            />
            <div className='grid grid-cols-12 mb-10 justify-between'>
                <div className='col-span-8 md:col-span-10'>
                    <Heading HeadingOne="Our" HeadingTwo="Team" />
                </div>
                <div className='col-span-4 md:col-span-2 content-center text-center'>
                    <Link to="/team"> <Button buttonText="View More" /> </Link>
                </div>
            </div>
        </div>
        <div className='bg-[#fa9db7] relative pt-14'>
            <div className='mx-auto lg:px-32 md:px-10 px-5'>
                <div className="bottom-0 left-0 absolute md:block hidden">
                    <img src="assets/ColumnImg/cloudOne.png" alt="Cloud 1" />
                </div>
                <div className="bottom-0 right-0 absolute md:block hidden">
                    <img src="assets/ColumnImg/cloudTwo.png" alt="Cloud 2" />
                </div>
                <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 md:pb-40 pb-10 relative'>
                    {
                        teamMember.map((i, index) => {
                            return (
                                <>
                                    <div className=' bg-white mb-7 p-4 rounded-lg'>
                                        <div className=''>
                                            <span className="box-border inline-block w-full opacity-100 border-0 relative max-w-full">
                                                <span style={{ width: 'initial', height: 'initial' }} className="box-border h-40 inline-block bg-none opacity-100 border-0 m-0 p-0 relative max-w-full">
                                                    <img src={i.memberImage} className="min-w-full max-w-full min-h-full max-h-full w-full block object-cover bg-none opacity-100 border-0 m-0 p-0" />
                                                </span>
                                            </span>
                                        </div>
                                        <div className='teamDescription'>
                                            <h3 className='text-center md:text-3xl text-2xl font-bold' style={{ color: colors[index] }}>{i.memberName} <br /> <span className='md:text-lg text-lg'> ({i.memberDesignation}) </span></h3>
                                            <p className='text-[#616262] lg:text-base text-sm leading-relaxed font-medium  pt-3'>{i.memberDescription}</p>
                                        </div>
                                    </div>

                                </>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </>)
}

export default OurTeam