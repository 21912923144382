import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faBuildingColumns, faUsers } from '@fortawesome/free-solid-svg-icons';

import CountUp from "react-countup";
import ScrollTiger from "react-scroll-trigger";

const ChooseUs = () => {
  const numbers = [
    {
      icon: faStar, 
      number: 4.9,
      numberName: "",
      title: "Google Rating"
    },
    {
      icon: faBuildingColumns,
      number: 100,
      numberName: "+",
      title: "Functioning Countries"
    },
    {
      icon: faUsers,
      number: 3,
      numberName: "Students",
      title: "Enrolled Students"
    },
  ];
  const [counter, setCounter] = useState(true);

  return (
    <div className="mt-8">
      <ScrollTiger onEnter={() => setCounter(true)} onExit={() => setCounter(false)} />
        <div className="relative pt-20 pb-20" style={{
            backgroundImage: `url("/assets/Img/bgImage.jpg")`,
            backgroundPosition: 'center', 
          }}>
            <div className=" absolute inset-0 bg-[#ffbc0a] opacity-[0.92]   rounded-lg z-0" style={{ pointerEvents: 'none' }}></div>
             <div className=" grid grid-cols-12 gap-6 container mx-auto" >
            {/* Left Section */}
            <div className="col-span-12 lg:col-span-4 p-5 flex flex-col justify-center relative z-10">
          <h5>
            <span className="text-[35px] font-seaweedScript font-medium text-[#f48506]">
              Why choose us
            </span>
          </h5>
          <h3 className="mb-5  font-bold text-gray-900 dark:text-white md:text-4xl lg:text-5xl">
            <span className="bg-clip-text  text-2xl font-extrabold  md:text-4xl lg:text-5xl text-white">Some Facts About Us</span>
          </h3>
        </div>
            
            {/* Right Section */}
            <div className="col-span-12 lg:col-span-8 gap-6 z-10">
            <div className="col-span-12 lg:col-span-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 relative z-10">
          {numbers.map((item, index) => (
            <div
              key={index}
              className="p-4 h-full text-center flex flex-col justify-center items-center "
            >
              <span className="text-[50px] mb-2 text-white">
                <FontAwesomeIcon icon={item.icon} />
              </span>
              <div className="flex items-baseline">
                {counter && (
                  <>
                    <CountUp
                      start={0}
                      end={item.number}
                      decimals={item.number % 1 !== 0 ? 1 : 0} 
                      duration={2}
                      delay={0}
                      className="text-4xl font-extrabold text-gray-800"
                    />
                    <span className="text-2xl font-semibold ml-1">{item.numberName}</span>
                  </>
                )}
              </div>
              <h5 className="mt-3 text-[20px]  text-gray-700">{item.title}</h5>
            </div>
          ))}
        </div>
                
            </div>
            </div>
        </div>
        </div>
  );
};

export default ChooseUs;
