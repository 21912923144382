import React from 'react'
import PageTitle from '../Header/PageTitle'
import CountrySlids from '../Home/CountrySlids'

import Mission from './Mission'
import Heading from '../Heading/Heading'
import OurValues from './OurValues'
import Objectives from './Objectives'

function About() {
  return (
    <div>
      <PageTitle title="About Us" activeTitle="About Us" />
      <div className='container relative lg:mt-20 mt-5'>
        <p className='text-[#616262] text-base text-center leading-relaxed font-medium '>Welcome to Bacchapanti, where we are dedicated to taking Vedic sciences to the world. Bacchapanti is a global leader in providing kids' development courses designed to sharpen young minds through the application of ancient wisdom and modern innovation.</p>
      </div>
      <div>
        <div className='px-5 md:mt-28 mt-12 text-center'>
          <Heading HeadingOne="Our" HeadingTwo="Mission" />
        </div>
        <Mission />
        <div className='px-5 md:mt-28 mt-12 text-center'>
          <Heading HeadingOne="Our" HeadingTwo="Values" />
        </div>
        <OurValues />
        <div className='px-5 md:mt-28 mt-12 text-center'>
          <Heading HeadingTwo="Objectives" />
        </div>
        <Objectives />
        <div className='container relative mt-20'>
          <p className='text-[#616262] text-base text-center leading-relaxed font-medium'>
            Remember, Bacchapanti’s journey began with a small room and a handful of kids, but our vision extends globally. We dream of reaching every city, both in India and beyond, through online and franchise models.
          </p>
        </div>
      </div>
      <CountrySlids />
    </div>
  )
}

export default About