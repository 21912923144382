import React, { useState } from 'react'


function ParentsModal({ VideoModalVideos }) {

    const [modal, setModal] = useState(false);

    const openModal = () => {
        setModal(!modal);
    };


    return (
        <>
            <div className='' onClick={openModal}>
                <div className="w-5 h-5 cursor-pointer bg-pink-200 rounded-full ring-4 ring-blue-200 grid place-items-center hover:bg-pink-400 hover:ring-blue-400 transition">
                    <svg className="ml-1 w-2" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z" fill="white" />
                    </svg>
                </div>
                {
                    modal ? (
                        <div className="fixed bg-black bg-opacity-80 inset-0 z-50 cursor-pointer p-4 md:p-5 opacity-100" modal={modal}>
                            <div className="relative w-full h-full mx-auto transform-none">
                                <div className="w-full md:w-6/12 sm:w-6/12 lg:w-7/12 absolute left-1/2 transform -translate-x-1/2 h-auto max-h-full top-1/2 -translate-y-1/2 rounded-lg">
                                    <button aria-label="Close panel" className="top-[5%] fixed z-10 inline-flex items-center justify-center w-7 h-7 md:w-8 md:h-8 rounded-full bg-pink-200 ring-4 ring-blue-200 hover:bg-pink-400 hover:ring-blue-400 transition  duration-200 focus:outline-none focus:text-gray-800 focus:shadow-md hover:text-gray-800 hover:shadow-md ltr:-right-3 lg:-right-2 md:-right-8 rtl:-left-3 ltr:md:-right-4 rtl:md:-left-4 right-0" onClick={setModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="w-8 h-6">
                                            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                    <div className="h-full overflow-y-auto rounded-lg ">
                                        <div className="w-full px-5 py-5 mx-auto overflow-hidden rounded-lg sm:w-96  md:w-full sm:px-8">
                                            <iframe
                                                width="100%"
                                                height="500"
                                                className='rounded-lg'
                                                src={VideoModalVideos}
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowfullscreen>
                                            </iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </>
    )
}

export default ParentsModal