import React from 'react'


const achievementList = [
    { achievement: 'Americas Got Talent' },
    { achievement: 'India Book Of Records' },
    { achievement: 'Public Performance' },
    { achievement: 'Media Exposure' }
]

const achievementimg = [
    { achievementimgs: 'assets/Reviews/Reviews1.png' },
    { achievementimgs: 'assets/Reviews/Reviews2.png' },
    { achievementimgs: 'assets/Reviews/Reviews3.png' },
    { achievementimgs: 'assets/Reviews/Reviews4.png' },
]

function KidsAchievement() {
    return (
        <div className='mt-28'>
            <div className="grid lg:grid-cols-2 md:grid-cols-1 bg-[#12265a]">
                <div className='relatived'>
                    <div className='md:container lg:pl-20 p-5 pb-0'>
                        <h1 className="mb-5 text-2xl font-extrabold text-gray-900 dark:text-white md:text-4xl lg:text-5xl">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r to-pink-400 from-red-200">Kid's Achievement</span>
                        </h1>
                        <p className='md:text-base text-sm  text-white'>
                            Our young achievers have consistently demonstrated exceptional talents, whether it's excelling in academics, winning prestigious competitions, or showcasing remarkable artistic skills.
                        </p>
                        <p className='md:text-base text-sm  text-white mb-3'>
                            At <b>Bacchapanti</b>, we foster an environment that encourages children to unlock their potential, guiding them towards success through personalized learning experiences and a supportive community.
                        </p>
                        <div className=''>
                            {
                                achievementList.map((achievements) => {
                                    return (
                                        <div className='flex gap-3 mb-2'>
                                            <div><img src="assets/Img/verified.png" className='w-7' /></div>
                                            <div className='md:text-lg text-sm  text-white'>{achievements.achievement}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='lg:pl-0 p-0 bottom-0'>
                        <div className="text-center cursor-pointer">
                            <img src="assets/Reviews/Reviews5.jpeg" className="" alt="" />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-2">
                    {
                        achievementimg.map((i) => {
                            return (
                                <div className="text-center cursor-pointer">
                                    <img src={i.achievementimgs} className="" alt="" />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default KidsAchievement