import React from "react";
import DemoForm from "./DemoForm";

const FreeDemo = () =>{

    return(
        <>
        <div className="mt-2">
      <div
        className=" pt-10 pb-10"
      >
        <div className="container mx-auto grid grid-cols-12 gap-6">
            <div className="bg-[#fff8e6] col-span-full rounded-2xl p-10 pl-36 pr-36 ">
          <div className=" z-10 mb-3 p-10 rounded-xl rounded-l-[40px]  text-center">
          <h5>
              <span className="text-[35px] font-seaweedScript font-medium text-[#f48506]">
               Why wait
              </span>
            </h5>
            <h3 className="text-2xl text-black font-bold md:text-4xl lg:text-5xl">
           Book a free demo now
            </h3>
            <p className="mt-5 text-xl text-gray-700">
            Our team will contact you within 24 hours to confirm your <br /> demo and schedule it as per our availibility.
          </p>
          </div>
          <div className="col-span-12">
            <DemoForm />
              </div>
              </div>
         
        </div>
      </div>
    </div>
        </>
    )
}

export default FreeDemo; 