import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import SecondFooter from './SecondFooter';
import MainFooter from './MainFooter';

function Footer() {

    const location = useLocation ();
return(
    <>
       {
                location.pathname === "/layout" ? <SecondFooter /> : <MainFooter />
        }
    </>
)

}

export default Footer