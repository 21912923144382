import React from 'react'
import Select from 'react-select';


function SingleSelectDropdown({ selectedOptions, selectedValue, handleSelectedChange, selectedDefaultValue, selectLabel }) {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: '100%',
            backgroundColor: 'white',
            border:'1px solid #d9dee3',
            borderRadius: '0.375rem',
            padding: '0.3rem',
            // boxShadow: state.isFocused ? '0 0 0 3px rgba(79, 70, 229, 0.2)' : 'none',

        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isFocused ? '#4F46E5' : '#A1A1AA',
            opacity: 1,
            '&:hover': {
                color: '#4F46E5',
            },
        }),
    };
    return (
        <>
            <label className='block text-[#566a7f] font-medium text-sm  mb-2'>{selectLabel}</label>
            <Select
                className="basic-single "
                classNamePrefix="select"
                name="color"
                defaultValue={selectedDefaultValue[0]}
                options={selectedOptions}
                value={selectedValue}
                onChange={handleSelectedChange}
                styles={customStyles}
            />
        </>
    )
}

export default SingleSelectDropdown