import React from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import SecondHeader from './SecondHeader';
import Navbar from './Navbar';

const Header = () =>{

    const location = useLocation ();
return(
    <>
       {
                location.pathname === "/layout" ? <SecondHeader /> : <Navbar />
        }
    </>
)

}

export default Header ;