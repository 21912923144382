import React from 'react'
import Heading from '../Heading/Heading'
import Button from '../Heading/Button'

function OurBlog() {

    const blogs = [
        {
            blogImage: 'assets/Img/Blog1.png',
            date: '28',
            month: 'august',
            blogHeading: 'BEHAVIOUR OF KIDS',
            blogDescription: 'Behaviour Means how to behave  or how to treat other people and all persons are identified by their behaviour. '
        },
        {
            blogImage: 'assets/Img/Blog2.png',
            date: '5',
            month: 'august',
            blogHeading: 'SELF CONFIDENCE ',
            blogDescription: 'Self-confidence acts like a tonic to the children as they have to learn and study more and more and making himself more trained is the primary function.'
        },
        {
            blogImage: 'assets/Img/Blog3.png',
            date: '31',
            month: 'July',
            blogHeading: 'KIDS AND FOOD',
            blogDescription: 'Living beings consume food not only to be alive but also to live a healthy and active life. Food has many types of nutrients which build the body, keep the body fit and give energy to do daily life activities .'
        }
    ]
    let colors = ['rgb(250,157,183)', 'rgb(69,179,223)', 'rgb(253,197,99)']



    return (
        <div className='mt-28 px-5'>
            <div className='text-center mb-10'>
                <Heading HeadingOne="Latest News" HeadingTwo="Our Blog" />
            </div>
            <div className='grid md:grid-cols-3 gap-4'>
                {
                    blogs.map((blog, index) => {
                        return (
                            <div className="flex rounded-lg h-full dark:bg-gray-800 bg-[colors] flex-col" style={{ backgroundColor: colors[index] }}>
                                <div className=''>
                                    <img src={blog.blogImage} className="inline-block" />
                                </div>
                                <div className='p-8'>
                                    <div className="mb-3">
                                        <h2 className=" dark:text-white text-2xl font-bold text-center">{blog.blogHeading}</h2>
                                    </div>
                                    <div className="">
                                        <p className="leading-relaxed text-lg text-white ">{blog.blogDescription}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default OurBlog