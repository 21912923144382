import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const SecondFooter = () =>{

  
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            // Adjust the threshold (e.g., 200) based on when you want the link to appear
            if (currentScrollY > 200) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        // Clean up the scroll event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (<>
        <footer className="footer  bg-[#6610f2] pt-9 relative text-gray-200 dark:text-gray-200 mt-8"  style={{
    WebkitMask: `
      radial-gradient(60.47px at 50% 82.5px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
      radial-gradient(60.47px at 50% -52.5px, #0000 99%, #000 101%) 50% 30px/120px 100% repeat-x
    `,
    mask: `
      radial-gradient(60.47px at 50% 82.5px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
      radial-gradient(60.47px at 50% -52.5px, #0000 99%, #000 101%) 50% 30px/120px 100% repeat-x
    `,
  }}
  >
        {/* <img src="assets/ColumnImg/footer_bottom.png" /> */}
            <div className=''>
                <div className="container relative py-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                    <div className="lg:col-span-5 pr-14 md:col-span-12">
                            <h3 className='text-3xl font-bold text-[#ffff]'>Academia</h3>
                                <p className="mt-5">Bachhapanti is one of the best educational institutes to help your child increase their focus
                                    and concentration using Vedic sciences techniques in a fun and challenging way. With Bachhapanti
                                    help find the true potential of your child
                                </p>
                        </div>
                        <div className="lg:col-span-3 md:col-span-12">
                       
                            <h3 className='text-3xl font-bold text-[#ffff]'>Get In Touch</h3>
                            <div className="flex items-center gap-2 mt-5">
                                <div className="relative items-center text-transparent">
                                    <div className="size-5 text-white hover:text-orange-500 dark:hover:text-orange-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="text-white font-medium">+919013790037</div>
                                </div>
                            </div>
                            <div className="flex items-center gap-2 mt-5 ">
                                <div className="relative items-center text-transparent">
                                    <div className="size-5 text-white rounded-xl hover:text-orange-500 dark:hover:text-orange-500 text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="text-white font-medium">bacchapantii@gmail.com</div>
                                </div>
                            </div>
                            <div className="flex items-center gap-2 mt-5">
                                <div className="relative items-center text-transparent">
                                    <div className="size-5 text-white rounded-xl hover:text-orange-500 dark:hover:text-orange-500 text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="text-white font-medium">4140 Parker Rd, Allentown, New Mexico 31134</div>
                                </div>
                            </div>
                           
                            
                        </div>

                        <div className="lg:col-span-4 md:col-span-12 flex items-center justify-center">
                            <div className="grid md:grid-cols-12 grid-cols-1">
                                <ul className="list-none mt-6 flex">
                                <li className="inline ml-2 mr-2 bg-white  text-[#6610f2] rounded-full"><Link to="" className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base hover:text-orange-500 dark:hover:text-orange-500 "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-linkedin h-4 w-4 align-middle" title="Linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg></Link></li>
                                <li className="inline ml-2 mr-2 bg-white  text-[#6610f2] rounded-full"><Link to="" className="size-8 inline-flex items-center justify-center tracking-wide align-middle hover:text-orange-500 dark:hover:text-orange-500 "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-facebook h-4 w-4 align-middle" title="facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></Link></li>
                                <li className="inline ml-2 mr-2 bg-white  text-[#6610f2] rounded-full"><Link to="" className="size-8 inline-flex items-center justify-center tracking-wide align-middle  hover:text-orange-500 dark:hover:text-orange-500"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-instagram h-4 w-4 align-middle" title="instagram"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></Link></li>
                                <li className="inline ml-2 mr-2 bg-white  text-[#6610f2] rounded-full"><Link to="" className="size-8 inline-flex items-center justify-center tracking-wide align-middle hover:text-orange-500 dark:hover:text-orange-500 "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-twitter h-4 w-4 align-middle" title="twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg></Link></li>
                                <li className="inline ml-2 mr-2 bg-white  text-[#6610f2] rounded-full"><Link to="" className="size-8 inline-flex items-center justify-center tracking-wide align-middle  hover:text-orange-500 dark:hover:text-orange-500"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-mail h-4 w-4 align-middle" title="email"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg></Link></li>
                            </ul>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
            <div className="lg:col-span-12 bg-[#6610f2] p-2 border-white border-t-2">
                    <ul className="list-none footer-list flex items-center justify-center ">
                                        <li className="p-2"><Link to="" className="flex text-gray-300 hover:text-gray-400 duration-500 ease-in-out">Privacy Policy</Link></li>
                                        <li className="p-2"><Link to="" className="flex text-gray-300 hover:text-gray-400 duration-500 ease-in-out">Terms & Condition</Link></li>
                                    </ul>
                    </div>
        </footer>
        <Link
            to="/contact"
            className={`fixed z-20 bottom-5 right-5 inline-block w-20 h-20 text-center rounded-full bg-[#45b3df] hover:bg-[#ffc85b] text-white p-2 transition duration-500 ${isVisible ? 'visible' : 'invisible'}`}
        >
            <div className='border-2 border-dashed border-white rounded-full w-16 h-16 p-3 text-xs font-bold'>
                Free Demo
            </div>
        </Link>
    </>
    )
}

export default SecondFooter;