import React from 'react'

function OurValues() {
    return (
        <div className=''>
            <div className='bg-no-repeat bg-cover p-20' style={{ backgroundImage: `url("assets/ColumnImg/columnPink.png")` }}></div>
            <div className='bg-[#ffb9cc] md:pb-2 pb-10 px-5 container'>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4 justify-between">
                    <div className='flex items-center gap-5'>
                        <div className='w-24 h-20 leading-3 text-center content-center rounded-lg bg-[#f39f5f33] basis-6/12'>
                            <img src="assets/About/value1.png" className='inline-block rounded-lg' />
                        </div>
                        <div>
                            <h3 className='text-[#385469] mb-2 text-lg font-bold'>Dedication to Excellence</h3>
                            <p className="text-[#ffffff] text-base leading-relaxed font-medium ">Commitment to delivering high-quality kids' development courses rooted in Vedic sciences.</p>
                        </div>
                    </div>
                    <div className='row-span-2 text-center lg:block hidden'>
                        <img src="assets/About/values.png" className='' />
                    </div>
                    <div className='flex items-center gap-5 text-right'>
                        <div>
                            <h3 className='text-[#385469] mb-2 text-lg font-bold'>Innovation</h3>
                            <p className="text-[#ffffff] text-base leading-relaxed font-medium ">Blend traditional Vedic wisdom with modern teaching methodologies to create unique learning experiences.</p>
                        </div>
                        <div className='w-24 h-20 leading-3 text-center content-center rounded-lg bg-[#f39f5f33] basis-7/12'>
                            <img src="assets/About/value2.png" className='inline-block rounded-lg' />
                        </div>
                    </div>
                    <div className='flex items-center gap-5'>
                        <div className='w-24 h-20 leading-3 text-center content-center rounded-lg bg-[#f39f5f33] basis-6/12'>
                            <img src="assets/About/value3.png" className='inline-block rounded-lg' />
                        </div>
                        <div>
                            <h3 className='text-[#385469] mb-2 text-lg font-bold'>Empowerment</h3>
                            <p className="text-[#ffffff] text-base leading-relaxed font-medium ">Empower children to unlock their full potential and become leaders in their own right.</p>
                        </div>
                    </div>
                    <div className='flex items-center gap-5 text-right'>
                        <div>
                            <h3 className='text-[#385469] mb-2 text-lg font-bold'>Inclusivity</h3>
                            <p className="text-[#ffffff] text-base leading-relaxed font-medium ">Embrace diversity and cater to children from all backgrounds and cultures.</p>
                        </div>
                        <div className='w-24 h-20 leading-3 text-center content-center rounded-lg bg-[#f39f5f33] basis-5/12'>
                            <img src="assets/About/value4.png" className='inline-block rounded-lg' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='' style={{ backgroundImage: `url("assets/ColumnImg/columnPinks.png")` }}></div>
        </div>
    )
}

export default OurValues