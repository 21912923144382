import React, { useState } from 'react'
import Childupgradation from './Childupgradation'
import ChildSkills from './ChildSkills'
import KidsAchievement from './KidsAchievement'
import ParentsReviews from './ParentsReviews'
import KidsReview from './KidsReview'
import Introduction from './Introduction'
import BestClasses from './BestClasses'
import CountrySlids from './CountrySlids'
import OurTeam from './OurTeam'
import EnrollNow from './EnrollNow'
import OurBlog from './OurBlog'
import OurGallery from './OurGallery'

function Home() {


  return (
    <div>
      <Introduction />
      <Childupgradation />
      <ChildSkills />
      <KidsAchievement />
      <ParentsReviews />
      <KidsReview />
      <BestClasses />
      <CountrySlids />
      <OurTeam />
      <OurGallery />
      <EnrollNow />
      {/* <OurBlog /> */}
    </div>
  )
}

export default Home