import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faBuildingColumns, faUsers } from '@fortawesome/free-solid-svg-icons';

const courseDetails = [
  { heading: "Relaxation Technique", icon: " ", para: "To calm the mind" },
  {
    heading: "100+ Brain Gym Exercises",
    icon: " ",
    para: "Balanced Cognitive Development",
  },
  {
    heading: "Recalling Activities",
    icon: " ",
    para: "Essential for Critical Thinking",
  },
  {
    heading: "Advance Math Techniques",
    icon: " ",
    para: "Accelerate Accuracy",
  },
  { heading: "Fun Brain Games", icon: " ", para: "Enhance problem solving" },
  { heading: "Blindfold Activities", icon: " ", para: "Enhance Sensory Power" },
];

function OurCourse() {
  return (
    <div className="mt-28">
      <div className="container mx-auto grid grid-cols-12 gap-6">
        {/* Left Side */}
        <div className="col-span-12 lg:col-span-6 pt-0 p-5">
          <h5 className="">
            <span className=" text-[30px] font-seaweedScript font-medium bg-clip-text text-[#f48506]">
              What we offer
            </span>
          </h5>
          <h1 className="mb-5 text-2xl font-bold  md:text-4xl lg:text-5xl">
            <span className="bg-clip-text">Our Courses</span>
          </h1>
          <p className=" text-[16px] text-black ">
            Bachhapanti uses well-developed brain enhancement techniques that make learning fun and effective <span>100</span> Our renowned Program has helped countless
            students boost their focus, concentration, and achieve extraordinary academic performance <span><FontAwesomeIcon icon={faTrophy}/></span>
          </p>
        </div>

        {/* Right Side */}
        <div className="col-span-12 lg:col-span-6 grid grid-cols-1 sm:grid-cols-2 gap-3">
          {courseDetails.slice(0, 2).map((item, index) => (
            <div
              key={index}
              className="p-4 h-full border-2 border-solid border-orange-600 rounded-[28px] shadow-md text-center flex flex-col items-center justify-between"
            >
              <div className="bg-[#fef1e6] p-4 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="50"
                  height="50"
                  fill="none"
                >
                  <path
                    d="M5 13l4 4L19 7"
                    fill="none"
                    stroke="#f1750c"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 9l4 4L19 3"
                    fill="none"
                    stroke="#f1750c"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h4 className="text-xl font-bold text-black m-1 min-h-6">
                {item.heading}
              </h4>
              <p className="text-[18px] font-semibold mb-2">{item.para}</p>
            </div>
          ))}
        </div>

        {/* Additional Course Details in a consistent size layout */}
        <div className="col-span-12 lg:col-span-12 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-3">
          {courseDetails.slice(2).map((item, index) => (
            <div
              key={index}
              className="p-4 h-full border-2 border-solid border-orange-600 rounded-[28px] items-center shadow-md text-center flex flex-col justify-between"
            >
              <div className="bg-[#fef1e6] p-4 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="50"
                  height="50"
                  fill="none"
                >
                  <path
                    d="M5 13l4 4L19 7"
                    fill="none"
                    stroke="#f1750c"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 9l4 4L19 3"
                    fill="none"
                    stroke="#f1750c"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h4 className="text-xl font-bold text-black m-3 min-h-6">
                {item.heading}
              </h4>
              <p className="text-[18px] font-semibold mb-2">{item.para}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurCourse;
