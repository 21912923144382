import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const AchievementData = [
    { 
        image: 'assets/Achievements/image-2.jpg',
        name: "Enna Baker",
        title: 'World Record: 100 Multiplications in 58 Seconds',
        para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    { 
        image: 'assets/Achievements/image-1.jpg',
        name: "John Doe",
        title: 'World Record: 100 Multiplications in 58 Seconds',
        para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
];

const Achievement = () => {
    const slideConfig = {
        loop: true,
        animateOut: 'fadeOut',
        margin: 10,
        nav: true,
        navSpeed: true,
        dots: false,
        autoplay: false,
        mouseDrag:true,
        autoplayTimeout: 1000,
        autoplayHoverPause: true,
        smartSpeed: 1000,

        navText: [
            '<span class="owl-prev arrow prev">‹</span>',
            '<span class="owl-next arrow next">›</span>'
          ],
        responsive: {
            0: { items: 1 },
            576: { items: 2 },
            768: { items: 2 },
            1200: { items: 2 }
        }
    };

    return (
        <div className="mt-4">
        <div className="relative pt-10 pb-10" style={{
            backgroundImage: `url("/assets/Img/bgImage.jpg")`,
            backgroundPosition: 'center', 
          }}>
            <div className=" absolute inset-0 bg-[#fff] opacity-[0.92]   rounded-lg z-0" style={{ pointerEvents: 'none' }}></div>
             <div className=" grid grid-cols-12 gap-6 container mx-auto" >
            {/* Left Section */}
            <div className="col-span-12  lg:col-span-4  mt-10 z-0">
                <h3 className="mb-5  text-2xl font-extrabold  md:text-4xl lg:text-5xl">
                    <span className="bg-clip-text leading-[62px] text-black">Achievements of Our Kids</span>
                </h3>
                <p className="pt-5 text-xl leading-[30px] text-black">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </p>
                
            </div>
            
            {/* Right Section */}
            <div className="col-span-12 lg:col-span-8 gap-6 z-10">
                <OwlCarousel className="owl-theme" {...slideConfig}>
                    {AchievementData.map((achievement, index) => (
                        <div className="flex flex-col items-center border-2 bg-white rounded-[30px]"  key={index}>
                            <img 
                                src={achievement.image} 
                                alt={`${achievement.name}'s achievement`} 
                                className=" h-80 object-cover border-2 rounded-tl-[30px] rounded-tr-[30px]"
                                
                            />
                            <div className="p-4">
                                <span className="text-gray-800 font-bold text-lg md:text-2xl">{achievement.name}</span>
                                <h5 className="text-black font-semibold text-lg">{achievement.title}</h5>
                                <p className="text-gray-600 mt-2 md:text-base text-sm">{achievement.para}</p>
                            </div>
                        </div>
                        
                    ))}
                    
                </OwlCarousel>
                
            </div>
            </div>
        </div>
        </div>
    );
};

export default Achievement;
