import React from "react";
import Introduction from "./Introduction";
import OurCourse from "../LandingPage/OurCourse";
import WhyUs from "./WhyUs";
import Testimonial from "./Testimonial";
import Events from "./Events";
import Diversity from "./Diversity";
import FreeDemo from "./FreeDemo";

const Layout = () =>{
    return(
        <>
        <Introduction />
        <OurCourse />
        <WhyUs />
        <Testimonial />
        <Events />
        <Diversity />
        <FreeDemo />
        </>
    );
}
export default Layout;