import React from 'react'

function Button({ buttonText }) {
  return (
    <div className=''>
      <button className='relative inline-block w-full text-center rounded-full bg-[#ffc85b]  hover:bg-[#45b3df] text-white p-2 transition duration-500'>
        <div className='border-2 border-dashed border-white rounded-full p-3 text-base font-bold'>
          {buttonText}
        </div>
      </button>
    </div>
  )
}

export default Button