import React from 'react'

function Heading({ MainHeading, spanHeading, mainSpanHeading }) {


    const myStyles = {
       color: '#000000',
       webkitTextStroke: '1px #000000',
       webkitTextFillColor: 'transparent',
   };

    return (
        <>
            <div className="section-title">
                <h4 className="text-primary-title xl:text-6xl lg:text-5xl text-3xl font-extrabold leading-normal">
                    {MainHeading}
                    <span style={myStyles} className="leading-normal"> {spanHeading}</span> {mainSpanHeading}
                </h4>
            </div>
        </>
    )
}

export default Heading