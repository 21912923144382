import React from "react";
import Introduction from "./Introduction";
import OurCourse from "./OurCourse";
import ChooseUs from "./ChooseUs";
import BookClass from "./BookClass";
import Achievement from "./Achievements";
import JoinBachapanti from "./JoinBachapanti";
import ParentsLove from "./ParentsLove";

const LandingPage = () =>{

    return (
        <>
        <Introduction />
        <OurCourse />
        <ChooseUs />
        <BookClass />
        <Achievement />
        <JoinBachapanti />
        <ParentsLove />
        </>
    )
}

export default LandingPage