import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Testimonials = [
    { 
        image: 'assets/Achievements/image-4.png',
        name: "Enna Baker",
        para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. ",
        parentInfo: "Enna Baker (mother of John)" 
    },
    { 
        image: 'assets/Achievements/image-4.png',
        name: "John Doe",
        para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. ",
        parentInfo: "Enna Baker (mother of John)"
    },
    { 
        image: 'assets/Achievements/image-4.png',
        name: "John Doe",
        para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. ",
        parentInfo: "Enna Baker (mother of John)"
    }
];

const ParentsLove = () => {

    const slideConfig = {
        loop: true,
        animateOut: 'fadeOut',
        margin: 10,
        nav: true,
        navSpeed: true,
        dots: false,
        autoplay: false,
        autoplayTimeout: 1000,
        autoplayHoverPause: true,
        smartSpeed: 1000,
        navText: [
            '<span class="owl-prev arrow prev">‹</span>',
            '<span class="owl-next arrow next">›</span>'
          ],
        responsive: {
            0: { items: 1 },
            576: { items: 2 },
            768: { items: 3 },
            1200: { items: 3 }
        }
    };

    return (
        <>
        <div className="grid grid-cols-12 ml-32 gap-6 container">
            <div className="mt-5  mb-5 bg-[#fac248] p-10 rounded-xl rounded-l-[40px] col-span-full text-center">
               <h3 className=" text-2xl font-extrabold  md:text-4xl lg:text-5xl">Why Parents Love Bachhapanti?</h3>
               <p className="mt-5 text-xl text-gray-700 m-24">Real Stories of Success and Transformation</p>
            </div>
            <div className="col-span-12 gap-6 -mt-36">
                <OwlCarousel className="owl-theme" {...slideConfig}>
                    {Testimonials.map((testimonial, index) => (
                        <div className="relative flex justify-center items-center p-4" key={index}>       
                         <div className="absolute  left-10 top-0 z-10 overflow-hidden bg-[#fac248] rounded-full w-32 h-32">
                            <img 
                            src={testimonial.image} 
                            alt={`${testimonial.name}'s achievement`} 
                            className="w-full object-cover h-44"
                            />
                         </div>
                            <div className="bg-white p-6 mt-9 pt-12 rounded-lg  border-b-4 border-b-[#fac248] relative ">
                            <h4 className="text-gray-800 pt-10 font-bold text-lg md:text-xl">{testimonial.name}</h4>
                            <p className="text-gray-600 mt-2 md:text-base text-sm">{testimonial.para}</p>
                            <p className="mt-4 text-[15px] italic text-gray-500">- {testimonial.parentInfo}</p>
                            </div>
                            
                        </div>
                    ))}
                </OwlCarousel>
            </div>
        </div>
        
        </>
    );
};

export default ParentsLove;
